import { NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';

export function createApollo(httpLink: HttpLink): any {
  const http = httpLink.create({ uri: window.location.protocol + '//' + window.location.host + '/api/gql' });
  let authToken = localStorage.getItem('auth') ? localStorage.getItem('auth') : null;

  const authMiddleware = setContext((operation, context) => {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`
    });

    return {
      headers
    };
  });

  return {
    link: authMiddleware.concat(http),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
